import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} alt="logo" class="logo" />
      <p>Website under development, coming soon!</p>
    </div>
  );
}

export default App;
